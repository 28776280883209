<template>
  <div>
    <v-app-bar-nav-icon
      v-if="mobile()"
      color="primary"
      class="ml-2"
      @click.stop="drawer = !drawer"
    />
    <v-navigation-drawer
      v-model="drawer"
      class="mobile-menu"
      temporary
    >
      <v-list>
        <v-list-item
          router
          to="/"
          :ripple="false"
        >
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-menu offset-x>
          <template #activator="{ on }">
            <v-list-group
              :ripple="false"
              v-on="on"
            >
              <template #activator>
                <v-list-item-title>
                  Shop
                </v-list-item-title>
              </template>
              <v-list-item
                v-for="(menu, index) in menuItems"
                :key="index"
                router
                :to="menuLink(menu.slug)"
                :ripple="false"
                class="mobile-menu__dropdown"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ menu.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :ripple="false"
                href="https://homeagain.homereserve.com/"
                class="mobile-menu__dropdown"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Like New Sectionals
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-menu>
        <v-list-item
          v-for="link in links"
          :key="link.name"
          router
          :to="link.route"
          :ripple="false"
        >
          <v-list-item-title>{{ link.name }}</v-list-item-title>
        </v-list-item>
        <v-menu offset-x>
          <template #activator="{ on }">
            <v-list-group
              :ripple="false"
              v-on="on"
            >
              <template #activator>
                <v-list-item-title>
                  Inspiration
                </v-list-item-title>
              </template>
              <v-list-item
                router
                to="/gallery"
                :ripple="false"
                class="mobile-menu__dropdown"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Gallery
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :ripple="false"
                href="https://www.homereserveservices.com/"
                target="_blank"
                class="mobile-menu__dropdown"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Free Design Services
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                router
                to="/family-friendly"
                :ripple="false"
                class="mobile-menu__dropdown"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Family-Friendly
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                router
                to="/favorite-layouts"
                :ripple="false"
                class="mobile-menu__dropdown"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Our Favorite Layouts
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item
                router
                to="/customer-layouts"
                :ripple="false"
                class="mobile-menu__dropdown"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Customer Layouts
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
            </v-list-group>
          </template>
        </v-menu>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  // import _ from 'lodash'

  export default {
    name: 'TheMobileMenu',
    data () {
      return {
          menuItems: [
          { name: 'Deep Seat Sectional', slug: 'deep-seat-sectionals' },
          { name: 'Standard Seat Sectional', slug: 'standard-seat-sectionals' },
          { name: 'Sofa', slug: 'sofas' },
          { name: 'Couch', slug: 'couches' },
          { name: 'Loveseat', slug: 'loveseats' },
          { name: 'Chair', slug: 'chairs' },
          { name: 'Chair & Half', slug: 'chair-half' },
          { name: 'Ottoman', slug: 'ottomans' },
          { name: 'New Fabric Cover', slug: 'covers' },
          { name: '$1 Fabric Sample', slug: 'swatches' },
        ],
        links: [
          { name: 'Features', route: '/features' },
          { name: 'Renewability', route: '/renewability' },
          { name: 'Made in USA', route: '/made_in_usa' },
          { name: 'No-Risk Trial', route: '/no_risk_trial' },
          { name: 'Customer Comments', route: '/customer_comments' },
          // { name: 'Blog', route: '/blog' },
          { name: 'Support', route: '/support' },
        ],
        title: 'TheMobileMenu',
        drawer: false,
      }
    },
    computed: {
      ...mapGetters({
        numberOfItems: 'cart/numberOfItems',
        // menuItems: 'initData/menuItems',
        // bottomMenuItems: 'initData/bottomMenuItems',
      }),
      // filteredMenuItems () {
      //   return _.filter(this.menuItems.concat(this.bottomMenuItems), (menuItem) => {
      //     return menuItem.attributes.name !== 'Pillow'
      //   })
      // },
    },
    methods: {
      menuLink (slug) {
        if (slug === 'new-covers') { return '/shop/covers/' }
        return `/shop/${slug}`
      },
    },
  }
</script>

<style lang="scss">
  .mobile-menu {
    height: 100vh;
    position: fixed;
    &__dropdown { // Nested shopping drowpdown
      background-color: scale-color($light-gray, $lightness: -5%);
    }
    .v-list-item--active {
      background-color: $light-gray;
    }
    .v-list-group__items {
      .v-list-item__title {
        padding-left: 1rem !important;
      }
    }
  }
  .mobile-menu .v-list-item:not(.v-list-item--disabled) {
    color: $primary-color !important;
    &::before { opacity: 0 !important; }
  }
  .mobile-menu .mdi-chevron-down { color: $primary-color; }
</style>
