<template>
  <div class="d-flex justify-space-between align-center sticky-navbar">
    <the-mobile-menu v-if="mobile()" />
    <div>
      <n-link class="logo" to="/" :class="mobile() ? 'd-flex justify-center' : ''">
        <v-img
          v-show="!mobile()"
          :src="require('@/assets/images/global/hr-logo-desktop.png')"
          class="ml-5"
          alt="hr logo"
          max-width="235"
          max-height="45"
          contain
        />
        <v-img
          v-show="loaded && mobile()"
          :src="require('@/assets/images/global/hr-logo-mobile.png')"
          class="logo-mobile"
          alt="mobile logo"
          max-width="230"
          height="40"
          contain
        />
      </n-link>
    </div>
    <nav class="nav-bar" :class="mobile() ? '' : 'mr-5' ">
      <v-container class="pa-0 d-flex">
        <v-row no-gutters>
          <v-col
            cols="12"
            class="pr-2"
          >
            <v-list id="nav-bar-list" class="d-flex">
              <v-menu v-if="!mobile()" open-on-hover offset-y>
                <template #activator="{ on, attrs }">
                  <v-list-item
                    color="primary"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-title tabindex="0" class="custom-font-size">
                      Shop
                      <span v-if="railsCheck()">
                        (Not Connected)
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <div class="shop-menu__container">
                  <v-list class="shop-menu__dropdown">
                    <div
                      v-for="(menu, index) in menuItems"
                      :key="index"
                    >
                      <v-list-item
                        :id="menu.id"
                        router
                        :to="menuLink(menu.slug)"
                        :ripple="false"
                      >
                        <v-list-item-title class="custom-font-size">
                          {{ menu.name }}
                        </v-list-item-title>
                      </v-list-item>
                      <div v-show="superMenu(menu.slug)" class="super-menu__flyout">
                        <v-row>
                          <v-col
                            cols="6"
                          >
                            <h4 class="super-menu__flyout__header qwincey primary--text mb-0">{{ menu.name }}</h4>
                            <h6 class="super-menu__flyout__subheader success-color--text">{{ menu.subheader }}</h6>
                            <p v-html="menu.desc"></p>
                            <div class="d-flex">
                              <v-img
                                class="mr-2"
                                :height="25"
                                :width="25"
                                :src="require('@/assets/images/success-checkmark.png')"
                              />
                              <p class="caption">Includes All Home Reserve Features</p>
                            </div>
                          </v-col>
                          <v-col
                            cols="6"
                          >
                            <cld-image
                              :public-id="(menu.img)"
                              alt="Deep Seat Sectionals"
                              class="mw-100"
                            >
                              <cld-transformation quality="auto" fetch-format="auto" />
                            </cld-image>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <v-list-item
                      :ripple="false"
                      href="https://homeagain.homereserve.com/"
                    >
                      <v-list-item-title class="custom-font-size">
                        Like New Sectionals
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
              <template v-if="!mobile()" class="mt-2">
                <v-list-item
                  v-for="link in links"
                  :key="link.name"
                  router
                  :to="link.route"
                  :ripple="false"
                  class="py-2"
                >
                  <v-list-item-title class="custom-font-size">
                    {{ link.name }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <v-menu v-if="!mobile()" open-on-hover offset-y>
                <template #activator="{ on, attrs }">
                  <v-list-item
                    color="primary"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-title tabindex="0" class="custom-font-size">
                      Inspiration
                      <span v-if="railsCheck()">
                        (Not Connected)
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list>
                  <v-list-item
                    router
                    to="/gallery"
                    :ripple="false"
                  >
                    <v-list-item-title class="custom-font-size">
                      Gallery
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :ripple="false"
                    href="https://www.homereserveservices.com/"
                    target="_blank"
                  >
                    <v-list-item-title class="custom-font-size">
                      Free Design Services
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    router
                    to="/family-friendly"
                    :ripple="false"
                  >
                    <v-list-item-title class="custom-font-size">
                      Family-Friendly
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    router
                    to="/favorite-layouts"
                    :ripple="false"
                  >
                    <v-list-item-title class="custom-font-size">
                      Our Favorite Layouts
                    </v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item
                    router
                    to="/customer-layouts"
                    :ripple="false"
                  >
                    <v-list-item-title class="custom-font-size">
                      Customer Layouts
                    </v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
              <!-- Setting min height to 36px for mobile -->
              <v-list-item class="py-2" @click="$modal.show('cart')">
                <a
                  v-show="showCart()"
                  href="#"
                >
                  <v-badge
                    v-show="loaded"
                    id="cart-count"
                    color="#445e3d"
                    :content="viewCartCount()"
                  />
                  <v-img
                    v-show="loaded"
                    :src="require('@/assets/images/global/shopping-cart-2021.png')"
                    alt="cart-logo"
                    max-width="36"
                    max-height="36"
                    contain
                  />
                </a>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </nav>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  // import _ from 'lodash'
  import TheMobileMenu from '~/components/TheMobileMenu.vue'

  export default {
    components: {
      TheMobileMenu,
    },
    data: () => ({
      menuItems: [
        {
          name: 'Deep Seat Sectional',
          slug: 'deep-seat-sectionals',
          subheader: 'Lounge & Relax',
          desc: 'Our "deepest" sectional, where <br>you can comfortably tuck your <br>feet up or stretch out to your <br>heart\'s content.',
          img: '/Shared/Navbar/super-menu__deep-seat.png',
          id: 'deep-seat-sectional',
        },
        {
          name: 'Standard Seat Sectional',
          slug: 'standard-seat-sectionals',
          subheader: 'Cozy & Supportive',
          desc: 'Our Classic Sectional. Perfect for those who love to sit supported with their feet on the ground. A customer favorite for decades.',
          img: '/Shared/Navbar/super-menu__standard-seat.png',
          id: 'standard-seat-sectional',
        },
        { name: 'Sofa', slug: 'sofas', id: 'sofa' },
        { name: 'Couch', slug: 'couches', id: 'couch' },
        { name: 'Loveseat', slug: 'loveseats', id: 'loveseat' },
        { name: 'Chair', slug: 'chairs', id: 'chair' },
        { name: 'Chair & Half', slug: 'chair-half', id: 'chair-half' },
        { name: 'Ottoman', slug: 'ottomans', id: 'ottoman' },
        { name: 'New Fabric Cover', slug: 'covers', id: 'cover' },
        { name: '$1 Fabric Sample', slug: 'swatches', id: 'swatche' },
      ],
      links: [
        { name: 'Features', route: '/features' },
        { name: 'Renewability', route: '/renewability' },
        { name: 'Made in USA', route: '/made_in_usa' },
        { name: 'No-Risk Trial', route: '/no_risk_trial' },
        { name: 'Customer Reviews', route: '/customer_comments' },
      ],
    }),
    computed: {
      ...mapGetters({
        // menuItems: 'initData/menuItems',
        // bottomMenuItems: 'initData/bottomMenuItems',
        numberOfItems: 'cart/numberOfItems',
      }),
      // filteredMenuItems () {
      //   return _.filter(this.menuItems.concat(this.bottomMenuItems), (menuItem) => {
      //     return menuItem.attributes.name !== 'Pillow'
      //   })
      // },
      loaded () {
        return this.$store.state.cart && this.$store.state.cart.status
      },
    },
    // session storage with default vue is tricky. To test, add items, hard refresh and ensure the count still shows by the cart
    created () {
      // the number of items
      if (this.$store.state.cart.lineItems.length === 0) { return 0 }
      const arrayOfNumbers = this.$store.state.cart.lineItems.map(obj => obj.quantity)
      const total = arrayOfNumbers.reduce((arrayOfNumbers, b) => {
        return parseInt(arrayOfNumbers, 10) + parseInt(b, 10)
      }, 0)
      this.itemCount = total
      return total
    },
    methods: {
      // dropDownName (menuName) {
      //   if (menuName === 'Cover') { return 'New Fabric Cover' }
      //   if (menuName === 'Swatch') { return 'Fabric Sample $1' }
      //   return menuName
      // },
      menuLink (slug) {
        if (slug === 'new-covers') { return '/shop/covers/' }
        return `/shop/${slug}`
      },
      superMenu (slug) {
        if (slug === 'deep-seat-sectionals') { return true }
        if (slug === 'standard-seat-sectionals') { return true }
        return false
      },
      mobile () {
        return !this.$vuetify.breakpoint?.mdAndUp
      },
      railsCheck () {
        return process.env.USE_RAILS_BACKEND !== 'true'
      },
      showCart () {
        if (this.$route.path.includes('checkout')) {
          return false
        }
        return true
      },
      setCount (count) {
        this.itemCount = count
      },
      // use item count on a fresh reload because mapGetters are not accessible in default vue on fresh reload
      // set itemCount to zero so that when the user removes all items, the count is still at zero
      viewCartCount () {
        if (this.numberOfItems > 0) {
          this.setCount(0)
          return this.numberOfItems
        }
          return this.itemCount || '0'
      },
    },
  }
</script>
<style lang=scss scoped>
  .sticky-navbar {
    background-color: white;
    min-height: 60px;
    z-index: 995;
    top: 0px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    box-shadow: 0 4px 6px -6px #000000;
  }
  #cart-count {
    z-index: 10;
    margin: -6px;
  }
  .custom-font-size {
    font-size: 16px !important;
  }
  .mobile-cart-icon {
    display: block;
    .button { display: inline-block; }
    .v-badge {
      /*position: relative;*/
      inset: 0 !important;
      display: inline-block;
      width: 20px;
      height: 20px;
    }
    .v-badge__badge {
      position: relative !important;
      inset: 0 !important;
      margin-top: 5px;
      background-color: $blue-green !important;
    }
  }
  .capitalize {
    text-transform: uppercase;
  }
  .v-menu__content {
    z-index: 1001 !important;
  }
  .nav-bar {
    background-color: white;
    top: 0;
    .v-toolbar__content {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .v-list {
      padding: 0;
      text-align: center;
      &-item {
        border-bottom: 3px solid transparent;
        transition: .25s ease-in-out;
        text-decoration: none;
        color:  $primary-color !important;
        &:hover {
          border-bottom: 3px solid $secondary-color;
          transition: .25s ease-in-out;
        }
        &--link:before {
          background-color: transparent !important;
        }
      }
      &-item--active {
        background-color: #f4e9e1;
        color: $secondary-color;
      }
    }
    .v-list {
      a {
        text-decoration: none;
      }
    }
  }

// Overrides for OOB Vuetify menu/lists behaviors and styles
.v-list-item:hover + .super-menu__flyout { display: block; }
.shop-menu__dropdown { overflow: visible; }
.v-menu__content {
  contain: none !important;
  overflow: visible;
}
.super-menu {
  &__flyout { // Flyout Parent Container
    background-color: $light-gray;
    border-top: 8px solid $quaternary-color;
    display: none;
    opacity: 1 !important;
    padding: $global-padding*2;
    position: absolute;
    top: 0;
    left: 197px;
    width: 600px;
    &__header {
      white-space: nowrap;
    }
  }
}
</style>
